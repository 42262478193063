var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-toolbar',{staticClass:"rounded-0 text-white",attrs:{"color":"primary","height":"80px"}},[_c('v-toolbar-title',{staticClass:"ml-3"},[_c('h2',[_vm._v("Agent Yönetimi")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['agent-management'],
        }),expression:"{\n          permissions: ['agent-management'],\n        }"}],staticClass:"mr-2",attrs:{"color":"error cursor-pointer","dark":"","disabled":!_vm.isSelectable},on:{"click":_vm.showDeleteAgentModal}},[_vm._v(" mdi-delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cursor-pointer","dark":""},on:{"click":_vm.fetchAgents}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v("Yenile")])]),_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0",attrs:{"label":"Ara","dark":"","hide-details":"auto","single-line":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{attrs:{"to":{ name: 'agent-management-edit', query: { editable: true } }}},[_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
            permissions: ['agent-management'],
          }),expression:"{\n            permissions: ['agent-management'],\n          }"}],attrs:{"elevation":"0","color":"success"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Yeni Agent ")],1)],1)],1),_c('KTTopMenu')],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agents,"search":_vm.search,"single-select":true,"sort-by":"id","sort-desc":"true","items-per-page":100,"loading":_vm.loading,"show-select":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{directives:[{name:"permission",rawName:"v-permission",value:({
                permissions: ['agent-management'],
              }),expression:"{\n                permissions: ['agent-management'],\n              }"}],attrs:{"to":{
                name: 'agent-management-edit',
                params: { id: item.id },
                query: { editable: true },
              }}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Görüntüle")])])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'agent-management-edit',
            params: { id: item.id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }